import { graphql } from 'gatsby';
import { createGatsbyPageComponent, EntityExplorerLayout } from '@shapeable/ui';
import { withAuthenticationRequired } from '@auth0/auth0-react';


export default createGatsbyPageComponent('Organisation', { layout: withAuthenticationRequired(EntityExplorerLayout) });

export const organisationQuery = graphql`
query OrganisationQuery($id: ID!) {
  platform { entityTypeDefinition(entityTypeName: "Organisation") { typeSlug }
  organisation(id: $id) {
    
      id slug name hashPath path url __typename _schema { label pluralLabel }
      openGraph { title description { id text } image { url url2x thumbnails { card { url url2x } } } }
      type { id name slug }
      twitter
      linkedin
      partnerOrganisations {
        id name slug path url __typename _schema { label pluralLabel }
        logo { id url url2x thumbnails { bubble { url url2x } } }
      }
      
        entityType { useAi }
        entityViews {
          id name hash label slug count showCount
          type { id name slug }
          slices {
            id name slug label 
            title 
            titleRich { text }
            layout { id name slug component }
            openGraph { 
              image { url thumbnails { large { url } bubble { url url2x } } }
            }
            emptyTout { text }
            ownEmptyTout { text }
            entityFormFields {
              name
              label
              description
              ownDescription
              type
              entityTypeName
            }
            headerFontType { internalName }
            bodyFontType { internalName }
            disabled
            connectedEntities { name label internalName }
            entityLayout { internalName }
            created updated
            outro { text }
            intro { text }
            content { id plain text }
            email
            phone
            twitter
            linkedin
            backgroundColor { id value }
            color { id value }
            teams { id name slug __typename _schema { label pluralLabel } }
            objectives { id name slug __typename type { id name slug __typename } }
            icon { id slug name component }
            people {
              id name hashPath slug path __typename
              organisation { id name }
              linkedin
              position
              openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            indicators { 
              id name slug title
            }
            indicatorGroups { 
              id slug name 
            }
            economies {
              id name slug path hashPath __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            } 
            locations { 
              id name slug __typename _schema { label pluralLabel } 
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            posts { 
              id name slug hashPath path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            feedEntries {
              name id slug path hashPath url __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { title image { url thumbnails { full { url } } } }
            }
            pages {
              id name slug path __typename _schema { label pluralLabel }
              type { id name slug }
              typeLabel
              openGraph { image { url url2x thumbnails { bubble { url url2x } } } }
            }
            organisations { 
              id name slug path hashPath __typename _schema { label pluralLabel }
              openGraph { image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
            }
            topicExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              topics { __typename outlineNumber id name slug path hashPath 
                openGraph {
                  title description { plain }
                  image { url url2x thumbnails { medium { url url2x } } }
                }
              }
            }
            trendExpertise {
              __typename typeLabel badge name slug
              levelOfExpertise { name slug }
              trends { __typename outlineNumber id path hashPath name slug
                openGraph { title description { plain } image { url url2x thumbnails { medium { url url2x } } }
                }
              }
            }
          }
        }
      
    
    entityViews {
      slices {
        technologies {
          id name slug __typename _schema { label pluralLabel }
          openGraph { description { id text } image { url } }
        }
      }
    }
  } }
}
`